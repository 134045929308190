





































































































































































































.q-page
  background white
  display flex
  flex-direction column
  align-items center

.q-card
  width 100%
  max-width 600px
  @media (max-width: 768px)
    margin 0

.width-600
  width 100%
  max-width 616px

h6
  margin-bottom 15px

.route-time
  display flex
  flex-direction row
  justify-content space-between
  margin-top 10px

.tickets-summary
  display grid
  grid-template-columns auto 60px
  grid-template-rows 1fr 10px
  grid-row-gap 10px
  grid-template-areas "type-number type-total" \ "type-separator type-separator"

.type-price
  text-align right

.type-number
  grid-area type-number

.type-total
  grid-area type-total
  text-align right

.type-separator
  grid-area type-separator

.price-summary
  display grid
  grid-template-columns auto 60px
  grid-template-rows 1fr 5px 1fr 5px 1fr 5px
  grid-row-gap 10px

.price-separator
  grid-column 1 / span 2

.tnc
  font-size 85%
  color rgba(0, 0, 0, 0.5)
  display flex
  flex-direction column
  label
    margin 5px 0
    display flex
    align-items flex-start
    position relative

a
  color convert(brandColor)
  font-weight 500

input[type="checkbox"]
  margin 5px
  appearance none
  border 1px solid
  padding 5px
  border-radius 2px
  &:checked
    padding 0 0.5px
    line-height 10px
    &:after
      content '\2714'
