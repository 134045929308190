























































































































































































form
  background white
  display flex
  flex-flow column
  height calc(100vh - 64px)
  @media (min-width: 768px)
    flex-flow row wrap
    justify-content space-around

.passenger-form
  display flex
  flex-direction column
  background white

.q-card
  @media (max-width: 768px)
    margin 8px 0
    box-shadow none

label
  display flex
  flex-direction column
  padding 8px 16px
  margin 10px 10px 0 10px
  border solid rgba(0,0,0,0.2) 1px
  border-radius 25px
  font-size 75%
  letter-spacing 0.00937em
  @media (min-width: 768px)
    border none
    input
      border-bottom solid rgba(0,0,0,0.2) 1px
  &.q-select
    font-size 16px
    margin 0 5px 0 0
    padding 0
    border none

.phone-label
  display flex
  flex-direction row

select
  border none
  background none
  font-size 16px
  margin-left -3px

@keyframes shake {
  0% {
    transform translateX(0)
  }
  25% {
    transform translateX(-2px)
  }
  50% {
    transform translateX(0)
  }
  75% {
    transform translateX(2px)
  }
  100% {
    transform translateX(0)
  }
}

.error
  color red
  padding 0 16px
  font-size 80%
  animation shake 0.1s 2

.submit-footer
  width 95%
  margin 2.5%
  display flex
  justify-content center

.submit-button
  width 100%
  max-width 600px
  color white
  background-color convert(brandColor)
  border none
  text-transform uppercase
  font-weight 500
  border-radius 10px
  padding 8px 16px
  align-self flex-end
