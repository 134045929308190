.results-list {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fff;
}
.continue-button {
  position: absolute;
  bottom: 15px;
  right: 5%;
  left: 5%;
  z-index: 100;
  width: 90%;
  max-width: 600px;
  border: none;
  background: #406e8e;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .continue-button {
    left: calc(50% - 300px);
    right: calc(50% - 300px);
  }
}
/*# sourceMappingURL=src/pages/ondemand/ferry/results.css.map */