.ferry-result-card {
  display: grid;
  grid-template-columns: 100px auto;
  grid-row-gap: 5px;
  grid-template-areas: "logo depart-time-origin" "logo arrival-time-destination" "separator separator" ". from" "price price";
  width: 90vw;
  max-width: 600px;
  margin-top: 20px;
  padding: 15px;
  color: #000;
  border-radius: 5px;
  line-height: 1;
  word-spacing: 0.5em;
}
.selected {
  animation: click 0.1s;
}
.logo {
  grid-area: logo;
  padding-right: 15px;
  margin: 5px 0;
}
.price {
  grid-area: price;
  font-size: 125%;
  font-weight: 500;
  line-height: 90%;
}
@-moz-keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-o-keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes click {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
/*# sourceMappingURL=src/pages/ondemand/ferry/ferry-result-card.css.map */